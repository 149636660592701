// If you want to override variables do it here
@import "variables";

// Import styles
@import "@coreui/coreui-pro/scss/coreui";
@import "@coreui/coreui-pro/scss/themes/dark";

@import "layout";

// If you want to add custom CSS you can put it here.
@import "custom";
